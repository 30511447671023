/* Main styles.scss file */

// Variables
$primary-color: #007bff;
$success-color: #28a745;
$dark-color: #055055;
$background-color: #f2f2f2;
$border-color: #ccc;

// Container styles
.upload-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $background-color;
  font-family: Arial, sans-serif;

  .fieldContainer {
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    border: 1px solid $border-color;
    background-color: $dark-color;
    padding: 50px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;

    h2 {
      margin-bottom: 20px;
      font-size: 2rem;
      color: #fff;
    }

    .circleInfo {
        border-radius: 100px;
        padding: auto;
        background: transparent !important;
        border: 1px solid #fff;
        margin-top: -8px;
        
        &:hover {
          background-color: #000 !important;
          border: 1px solid transparent;
        }

    }
  }
}

// Form and input styles
.upload-form {
  width: 100%;
}

.file-input-container {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
}

.file-input-label {
  background-color: $primary-color;
  color: white;
  padding: 6px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

#file {
  display: none;
}

.upload-button {
  background-color: $success-color;
  color: white;
  padding: 6px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($success-color, 10%);
  }
}

.upload-message {
  margin-top: 30px;
  padding: 12px 20px;
  background-color: $success-color;
  color: white;
  border-radius: 5px;
  font-size: 0.9rem;
}

@media (max-width: 600px) {
  .upload-container {
    .fieldContainer {
      padding: 30px;
    }
  }
}