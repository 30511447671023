html {
  background-color: #292e33;
}

.download-btn {
  padding: 10px 10px;
  margin: 5px;
  background-color: #27292b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #283136;
  }

}

@media only screen and (max-width: 768px) {

  .tableContainer {
    display: flex;
    flex-direction: column;
  }

  .tableDivider {
    width: 100% !important;
  }

  .fno-tab {
    width: 100% !important;
    margin-bottom: 50px;
    padding-top: 20px;
  }

  .pnc-tab {
    width: 100% !important;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}